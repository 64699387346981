<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col">
        <h1 class="text-center">Trepr News</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card py-5 bg-light h-100">
          <div class="card-body">
            <h5 class="card-title text-primary">In the News</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              Trepr has started its journey in September 2015
            </h6>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card py-5 bg-light h-100">
          <div class="card-body">
            <h5 class="card-title text-primary">Press</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              More response over social media, and Treper is progressing in rapid pace
            </h6>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card py-5 bg-light h-100">
          <div class="card-body">
            <h5 class="card-title text-primary">Blog & Enquiries</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              Visit our updates in major social networking sites
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsRoom',
  created() {
    document.title = 'Trepr - News Room';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr Newsroom is a home for news and announcements from Trepr, a global community of Seeker and travelers.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
